@import '~@fontsource/open-sans/cyrillic-300.css';
@import '~@fontsource/open-sans/cyrillic-400.css';
@import '~@fontsource/open-sans/cyrillic-600.css';
@import '~@fontsource/open-sans/cyrillic-700.css';
@import '~@fontsource/open-sans/300.css';
@import '~@fontsource/open-sans/600.css';
@import '~@fontsource/open-sans/400.css';
@import '~@fontsource/open-sans/700.css';

@import '~@fontsource/archivo/300.css';
@import '~@fontsource/archivo/400.css';
@import '~@fontsource/archivo/500.css';
@import '~@fontsource/archivo/600.css';
@import '~@fontsource/archivo/700.css';

body {
    font-family: 'Archivo', 'Open Sans', Arial, Helvetica, sans-serif;

    margin: 0;
    background-color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* background-color: white; */
    /* font-family: 'Archivo', 'Open Sans', Arial, Helvetica, sans-serif; */
}

button {
    font-family: 'Archivo', 'Open Sans', Arial, Helvetica, sans-serif;

    cursor: pointer;

    border: none;
    background-color: transparent;
}

select {
    outline: none;
}